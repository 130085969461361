import React from 'react';
import { Service, Content } from './styles';
import Section from '../Section';
import { FaMobile } from 'react-icons/fa';
import { IoIosDesktop, IoIosSettings } from 'react-icons/io';

const services = [
	{
		name: 'Desenvolvimento de aplicativos',
		icon: <FaMobile size={130} color="#555" />,
		description: 'Aplicativos Android e iOS, tenha tudo o que precisa na palma de suas mãos'
	},
	{
		name: 'Desenvolvimento de sites',
		icon: <IoIosDesktop size={130} color="#555" />,
		description: 'Divulge seu negócio a partir de um site para atingir novos clientes e fazer vendas online'
	},
	{
		name: 'Desenvolvimento de sistemas',
		icon: <IoIosSettings size={130} color="#555" />,
		description: 'Automatize e gerencie a gestão do seu negócio de forma simples e fácil'
	}
];

export default function Services() {
	return (
		<Section title="Serviços" message="Já pensou em escalar seu negócio usando tecnologia?" id="serviços">
			<Content>
				{services.map((item) => (
					<Service key={item.name}>
						{item.icon}
						<span>{item.name}</span>
						<p>{item.description}</p>
					</Service>
				))}
			</Content>
		</Section>
	);
}
