import React, { useEffect, useState } from 'react';
import Header from './Header';
import Links from './Links';
import { Container } from './styles';

export default function Navbar() {
	const [ navbarOpen, setNavbarOpen ] = useState(false);
	const [ navbarColor, setNavbarColor ] = useState('transparent');

	useEffect(() => {
		const updateNavbarColor = () => {
			if (document.documentElement.scrollTop > 199 || document.body.scrollTop > 199) {
				setNavbarColor('#fff');
			} else if (document.documentElement.scrollTop < 200 || document.body.scrollTop < 200) {
				setNavbarColor('transparent');
			}
		};

		typeof window !== 'undefined' && window.addEventListener('scroll', updateNavbarColor);

		return function cleanup() {
			typeof window !== 'undefined' && window.removeEventListener('scroll', updateNavbarColor);
		};
	});

	const handleNavbar = () => setNavbarOpen(!navbarOpen);

	return (
		<Container navbarColor={navbarColor}>
			<Header handleNavbar={handleNavbar} navbarColor={navbarColor} setNavbarColor={setNavbarColor} />
			<Links navbarOpen={navbarOpen} navbarColor={navbarColor} setNavbarOpen={setNavbarOpen} />
		</Container>
	);
}
