import styled from 'styled-components';

export const Container = styled.nav`
	overflow: hidden;
	position: fixed; /* Set the navbar to fixed position */
	top: 0; /* Position the navbar at the top of the page */
	min-width: 100%;
	background: ${(props) => props.navbarColor};
	padding: 1rem 5rem 1rem 5rem;
	${(props) =>
		props.navbarColor === '#fff' &&
		'box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);'} transition: all 0.3s linear;
	@media (min-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	@media (max-width: 900px) {
		padding: 0;
	}
`;
