import React from 'react';
import { Container, Button } from './styles';
import { Link } from 'gatsby';

export default function Banner({ title, subtitle, children }) {
	return (
		<Container>
			<h1 className="title">{title}</h1>
			<h1 className="subtitle">{subtitle}</h1>
			<Link to="#contato">
				<Button>Faça seu orçamento</Button>
			</Link>
		</Container>
	);
}
