import React, { Component } from 'react';
import { Link } from 'gatsby';
import { IoIosMenu } from 'react-icons/io';
import styled from 'styled-components';
import colors from '../../utils/colors';

export default class Header extends Component {
	render() {
		const { handleNavbar } = this.props;
		return (
			<Container navbarColor={this.props.navbarColor}>
				<Link to="/" className="logo">
					ROGERD RIBEIRO
				</Link>
				<IoIosMenu
					size={45}
					className="toggle-icon"
					onClick={() => {
						handleNavbar();
						this.props.setNavbarColor('#fff');
					}}
				/>
			</Container>
		);
	}
}

export const Container = styled.header`
	padding: 0.5rem 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.toggle-icon {
		font-size: 1.75rem;
		color: #d2aa5c;
		cursor: pointer;
	}
	.logo {
		font-size: 1rem;
		letter-spacing: 0.1rem;
		text-decoration: none;
		font-family: 'Oswald', sans-serif;
		letter-spacing: 0.2rem;
		color: ${(props) => (props.navbarColor === '#fff' ? '#555' : '#ddd')};
		&:hover {
			color: ${colors.primary};
			cursor: pointer;
		}
	}
	@media (min-width: 768px) {
		.toggle-icon {
			display: none;
		}
	}
`;
