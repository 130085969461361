import styled from 'styled-components';
import colors from '../../utils/colors';

export const Container = styled.main`
	margin-bottom: -3rem;
	text-align: center;
	.title {
		color: #fff;
		font-size: 3rem;
		text-transform: uppercase;
		letter-spacing: 1rem;
		font-family: 'Oswald', sans-serif;
		font-weight: bold;
		margin-bottom: 1.5rem;
	}
	.subtitle {
		color: ${colors.primary};
		font-family: 'Caveat Brush', cursive;
		font-size: 1.7rem;
		font-weight: normal;
		text-transform: capitalize;
		margin: 0rem 0px 5rem 0px;
	}
	@media (max-width: 425px) {
		.title {
			font-size: 2rem;
		}
		.subtitle {
			font-size: 1.3rem;
		}
		padding: 0px 10%;
	}
`;
export const Button = styled.button`
	color: #fff;
	background: transparent;
	padding: 0.5rem 1rem;
	text-transform: uppercase;
	font-size: 1rem;
	padding-left: 1.5rem;
	letter-spacing: 0.5rem;
	font-weight: 700px;
	border: 1px solid white;
	&:hover {
		transition: all 0.3s linear;
		background: #fff;
		color: #222;
		cursor: pointer;
	}
	@media (max-width: 425px) {
		font-size: 0.8rem;
	}
`;
