import styled from 'styled-components';
import colors from '../../utils/colors';

export const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 4rem 0;
	/* width: 85%; */
`;
export const ContentText = styled.div`
	margin-left: 1.4rem;
	max-width: 600px;
	min-width: 300px;
	span {
		font-weight: bold;
		color: #444;
		font-size: 1.1rem;
		padding: 0.5rem 0;
	}
	p {
		color: #888;
		font-size: 1.1rem;
		margin-top: 1rem;
	}
	@media (max-width: 922px) {
		margin-top: 2rem;
	}
`;
export const Container = styled.section`
	display: flex;
	flex-direction: column;
	padding: 2rem 0;
	margin: 0 auto;
`;
export const Img = styled.img`
	padding: 0.5rem 1rem;
	/* height: 400px;
	width: 300px; */
	@media (max-width: 800px) {
		height: 300px;
		width: 250px;
	}
`;
