import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import Navbar from './Navbar';
import Footer from './Footer';

export const GlobalStyle = createGlobalStyle`
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html{
	scroll-behavior: smooth;
}
body{
	font-family: 'Roboto', sans-serif;
	color: #262626;
	background-color: #fff;
}
a{
	text-decoration: none;
}
`;

const Layout = ({ children }) => {
	return (
		<Fragment>
			<GlobalStyle />
			<Navbar />
			{children}
			<Footer id="contato" />
		</Fragment>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
