import styled from 'styled-components';
import colors from '../../utils/colors';
export const Icons = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	@media (max-width: 500px) {
		flex-direction: column;
	}
`;
export const Icon = styled.a`
	transition: all 0.4s linear;
	font-size: 150px;
	margin: 0 2rem;
	color: #ccc;
	&:hover {
		color: ${colors.primary};
	}
	@media (max-width: 500px) {
		font-size: 100px;
	}
`;
export const ItemContact = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
export const Text = styled.span`
	font-size: 1rem;
	font-weight: bold;
	color: ${colors.lighter};
`;
export const Foooter = styled.footer`
	display: flex;
	justify-content: center;
	padding: 10px 0px;
	background-color: #fff;
	span {
		color: #333;
	}
`;
