import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Home from '../components/Home';
import img from '../images/home2.jpeg';
import Banner from '../components/Banner';
import Services from '../components/Services';
import Portfolio from '../components/Portfolio';
import About from '../components/About';
import 'bootstrap/dist/css/bootstrap.min.css';

const IndexPage = () => (
	<Layout>
		<SEO title="Rogerd Ribeiro" />
		<Home img={img}>
			<Banner
				title="Rogerd Ribeiro"
				subtitle="Leve seu negócio para o próximo nível utilizando tecnologia & inovação!"
			/>
		</Home>
		<Services />
		<Portfolio />
		<About />
	</Layout>
);

export default IndexPage;
