import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import colors from '../../utils/colors';
// import { Container } from './styles';

export default class Links extends Component {
	state = {
		links: [
			{
				id: 1,
				path: '#serviços',
				name: 'serviços'
			},
			{
				id: 2,
				path: '#portifólio',
				name: 'portifólio'
			},
			{
				id: 3,
				path: '#sobre',
				name: 'sobre'
			},
			{
				id: 4,
				path: '#contato',
				name: 'contato'
			}
		]
	};
	render() {
		return (
			<Container open={this.props.navbarOpen} navbarColor={this.props.navbarColor}>
				{this.state.links.map((link) => (
					<Link
						to={link.path}
						key={link.id}
						className="nav-link"
						onClick={() => this.props.setNavbarOpen(false)}
					>
						{link.name}
					</Link>
				))}
			</Container>
		);
	}
}

export const Container = styled.ul`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
	li {
		list-style-type: none;
		display: flex;
		flex-direction: column;
	}
	.nav-link {
		font-size: 1rem;
		display: block;
		font-family: 'Oswald', sans-serif;
		text-decoration: none;
		padding: 0.5rem 1rem 0.3rem 1rem;
		color: ${colors.dark};
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		cursor: pointer;
		transition: all 0.3s linear;
		color: ${(props) => (props.navbarColor === '#fff' ? '#555' : '#ddd')};
		&:hover {
			color: ${colors.primary};
			background: transparent;
		}
	}
	transition: all 0.4s linear;
	height: ${(props) => (props.open ? 152 : 0)}px;
	overflow: hidden;

	@media (min-width: 768px) {
		display: flex;
		flex-direction: row;
		height: auto;
		margin: 0 auto;
	}
`;
