import styled from 'styled-components';
import colors from '../../utils/colors';

export const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	width: 90%;
	.container{
		display: flex;
		flex:1;
		align-items: center;
		justify-content: center;
		padding:0;
	}
	.iconNext,
	.iconBack {
		font-size: 50px;
		&:hover{
			cursor:pointer;
		}
		@media (max-width: 475px) {
			font-size: 35px;
		}
	}
`;
export const Service = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	max-width: 350px;
	@media (max-width: 425px) {
		width: 250px;
		padding: 0;
		img {
			width: 200px;
			height: 200px;
		}
	}

	img {
		max-width: 250px;
		height: 250px;
		border-radius: 5px;
	}
	span {
		font-weight: bold;
		color: ${(props) => (props.dark ? 'white' : colors.dark)};
		font-size: 1.1rem;
		padding: 0.5rem 0;
		text-align: center;
	}
	p {
		color: ${(props) => (props.dark ? colors.lighter : colors.dark)};
		font-size: 1.1rem;
		text-align: center;
	}
	a{
		color: ${colors.primary};
		&:hover{
			cursor: pointer;
		}
	}
`;
export const Container = styled.section`
	display: flex;
	flex-direction: column;
	padding: 2rem 0;
	margin: 0 auto;
`;
export const SectionButton = styled.button`
	align-self: center;
	margin: 2rem 0;

	color: ${colors.dark};
	background: transparent;
	padding: 0.5rem 1rem;
	text-transform: uppercase;
	font-size: 1rem;
	padding-left: 1.5rem;
	letter-spacing: 0.5rem;
	font-weight: 700px;
	border: 1px solid ${colors.dark};
	&:hover {
		background: ${colors.dark};
		color: ${colors.white};
		cursor: pointer;
		transition: all 0.3s linear;
	}
`;
