import styled from 'styled-components';

export const Container = styled.header`
	min-height: calc(100vh);
	background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, 0.8)),
		url(${(props) => props.img}) center/cover fixed no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
`;
