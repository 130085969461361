import styled from 'styled-components';
import colors from '../../utils/colors';

export const Container = styled.section`
	padding: 5rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: ${(props) => 100 - props.removeHeight}vh;
	background-color: ${(props) => (props.dark ? colors.dark : 'white')};
`;
export const Title = styled.h1`
	font-family: 'Oswald', sans-serif;
	font-weight: bold;
	letter-spacing: 0.3rem;
	font-size: 2rem;
	color: ${(props) => (props.dark ? colors.lighter : colors.dark)};
	text-transform: uppercase;
	@media (max-width: 425px) {
		font-size: 1.8rem;
	}
`;

export const Message = styled.h3`
	font-family: 'Caveat Brush', cursive;
	letter-spacing: 0.2rem;
	color: ${colors.primary};
	font-size: 2rem;
	margin-top: 1rem;
	text-align: center;
	@media (max-width: 425px) {
		font-size: 1.5rem;
	}
`;
export const Underline = styled.div`
	width: 5rem;
	height: 0.2rem;
	background: ${colors.primary};
	margin: .5rem auto;
`;
