import React, { Fragment } from 'react';
import { Icons, Icon, ItemContact, Text, Foooter } from './styles';
import { FaLinkedin } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { IoLogoWhatsapp } from 'react-icons/io';
import Section from '../Section';
import ReactTooltip from 'react-tooltip';

export default function Footer() {
	const icons = [
		{
			id: 1,
			icon: <MdEmail className="icon" />,
			placeholder: 'Clique para abrir o E-mail',
			value: 'rogerdribeiro@gmail.com',
			path: 'mailto:rogerdribeiro@gmail.com'
		},
		{
			id: 2,
			placeholder: 'Abrir WhatsApp',
			value: '31 998446600',
			icon: <IoLogoWhatsapp className="icon" />,
			path: 'https://api.whatsapp.com/send?phone=5531998846600'
		},
		{
			id: 3,
			placeholder: 'Clique para abrir o LinkedIn',
			value: 'Rogerd Ribeiro',
			icon: <FaLinkedin className="icon" />,
			path: 'https://www.linkedin.com/in/rogerdribeiro'
		}
	];
	return (
		<Fragment>
			<Section dark title="Contato" message="Entre em contato" id="contato" removeHeight={10}>
				<ReactTooltip />
				<Icons>
					{icons.map((i) => (
						<ItemContact>
							<Icon href={i.path} key={i.id} data-tip={i.placeholder} data-place="bottom" target="_blank">
								{i.icon}
							</Icon>
							<Text>{i.value}</Text>
						</ItemContact>
					))}
				</Icons>
			</Section>
			<Foooter>
				<Text> Copyright &copy; 2021 - Rogerd Ribeiro</Text>
			</Foooter>
		</Fragment>
	);
}
