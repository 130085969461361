import React, { useState } from 'react';
import { Service, Content } from './styles';
import Section from '../Section';
import { IoIosArrowForward as Next, IoIosArrowBack as Back } from 'react-icons/io';
import colors from '../../utils/colors';
import { useEffect } from 'react';

const portfolio = [
	{
		name: 'Aplicativo Flynow (Android & iOS)',
		link: 'https://appflynow.com',
		img: require('../../images/portfolio/aplicativoFlynowProdutividade.png'),
		description: 'Aplicativo para gerenciamento de tarefas, hábitos e metas'
	},
	{
		name: 'Plataforma Web & Extensão (Chrome)',
		link: 'https://web.appflynow.com',
		img: require('../../images/portfolio/plataformaWebFlynow.png'),
		description: 'Plataforma Web e Extensão do Chrome para o Aplicativo Flynow'
	},
	{
		name: 'Site & Blog',
		link: 'https://appflynow.com',
		img: require('../../images/portfolio/plataformaWebFlynow.png'),
		description: 'Site e Blog para o Aplicativo Flynow'
	},
	{
		name: 'Aplicativo Performance Multi-Uso',
		img: require('../../images/portfolio/aplicativoPerformance.png'),
		description: 'Aplicativo que conecta prestadores de serviços a clientes.',
	},
	{
		name: 'Sistema administrativo',
		img: require('../../images/portfolio/dashboardPerformance.png'),
		description: 'Sistema administrativo para o aplicativo Performance Multi-Uso.',
	},
	{
		name: 'Aplicativo Cachaça Gestor',
		img: require('../../images/portfolio/cachacaGestor.png'),
		description: 'Aplicativo para gestão de alambiques',
		link: 'https://play.google.com/store/apps/details?id=com.app_cachacagestor'
	},
	{
		name: 'Aplicativo Mobes',
		img: require('../../images/portfolio/aplicativoMobes.png'),
		description: 'Aplicativo de mobilidade urbana voltado ao público universitário.',
		link: 'https://appmobes.com.br/'
	},
	{
		name: 'Aplicativo Gerenciamento de Produtos',
		img: require('../../images/portfolio/gerenciamentoProdutos.png'),
		description: 'Aplicativo para gerenciamento de produtos, ideal para mercearias.'
	},
	{
		name: 'Aplicativo Inspirando Mentes',
		img: require('../../images/portfolio/inspirandoMentes.png'),
		description: 'Aplicativo de frases inspiradoras diárias, e conteúdos sobre desenvolvimento pessoal.',
		link: 'https://play.google.com/store/apps/details?id=com.flynow.frases'
	},
];

export default function Portfolio() {
	const [ page, setPage ] = useState(1);
	const [ ammountItemsInPage, setAmmountItemsInPage ] = useState(getAmmountItemsInPage());
	const qntPage = Math.ceil(portfolio.length / ammountItemsInPage);

	function getAmmountItemsInPage() {
		if (typeof window !== 'undefined' && window.innerWidth < 800) {
			return 1;
		} else if (typeof window !== 'undefined' && window.innerWidth < 1200) {
			return 2;
		} else return 3;
	}
	useEffect(() => {
		const updateWidthScreen = () => {
			setAmmountItemsInPage(getAmmountItemsInPage());
		};

		typeof window !== 'undefined' && window.addEventListener('resize', updateWidthScreen);

		return function cleanup() {
			typeof window !== 'undefined' && window.removeEventListener('resize', updateWidthScreen);
		};
	});
	const renderPortifolio = () => {
		const portifofioRender = portfolio.slice(
			page * ammountItemsInPage - ammountItemsInPage,
			page * ammountItemsInPage
		);
		return portifofioRender.map((item) => (
			<Service key={item.name} dark>
				<img src={item.img} alt={item.name} />
				<span>{item.name}
				</span>
				<p>{item.description}</p>
				{
					item.link ? (
						<a href={item.link} target="_blank" > VER DETALHES</a>
					)
					: <div style={{padding:20}}></div>
				}
			</Service>
		));
	};

	function handlePage(ac) {
		if (ac === -1 && page === 1) return;
		if (ac === 1 && (page + 1) * ammountItemsInPage - (ammountItemsInPage - 1) > portfolio.length) return;
		setPage(page + ac);
	}
	function renderPagination() {
		let pagination = [];
		for (let i = 1; i <= qntPage; i++) pagination.push(i);
		return (
			<div style={{ display: 'flex' }}>
				{pagination.map((i) => (
					<div
						style={{
							height: 20,
							width: 20,
							borderRadius: 50,
							marginLeft: 5,
							backgroundColor: i === page ? colors.primary : '#ccc'
						}}
					/>
				))}
			</div>
		);
	}
	return (
		<Section dark title="Portifólio" message="Conheça meus projetos já realizados" id="portifólio">
			<Content>
				<Back color={page === 1 ? "#333": "#ccc"} className="iconBack" onClick={() => handlePage(-1)} />
				<div className="container">
					{renderPortifolio()}
				</div>
				<Next color={page !== qntPage ? "#ccc": "#333"} className={'iconNext'} onClick={() => handlePage(1)} />
			</Content>
			{renderPagination()}
		</Section>
	);
}
