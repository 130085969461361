import React from 'react';
import { Container, Title, Message, Underline } from './styles';

export default function Section({ id, children, message, title, dark, removeHeight = 0 }) {
	return (
		<Container id={id} dark={dark} removeHeight={removeHeight}>
			<Title dark={dark}>{title}</Title>
			<Underline />
			<Message>{message}</Message>
			{children}
		</Container>
	);
}
