import React from 'react';
import { Img, ContentText, Content } from './styles';
import Section from '../Section';

export default function About() {
	return (
		<Section title="Sobre" message="Conheça um pouco mais sobre mim" id="sobre">
			<Content>
				<Img src={require('../../images/rogerd.png')} alt="Rogerd Ribeiro" />
				<ContentText>
					<span>CIÊNCIA DA COMPUTAÇÃO | EMPREENDEDORISMO | DESENVOLVIMENTO PESSOAL </span>
					<p>
						Sou apaixonado por tecnologia, empreendedorismo e desenvolvimento pessoal. <br/>
						Graduado em Ciência da Computação pela Universidade Federal de Ouro Preto (UFOP), 
						onde tive a oportunidade de fazer parte da Empresa Júnior do curso de Computação (Voluta),
						e também da Startup Cachaça Gestor, trabalhando de forma remota.
						<br/> 
						Nos útimos anos, realizei vários projetos como freelancer, todos voltados para criação de aplicativos, sites e sistemas.
						Além disso, realizei diversos cursos relacionados à programação, tecnologia, empreendedorismo, marketing digital e desenvolvimento pessoal. 
						<br/> 
						Todas essas experiências foram essenciais para o meu crescimento profissional e pessoal.
					</p>
				</ContentText>
			</Content>
		</Section>
	);
}
